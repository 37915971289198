define("discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-contents-cat", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each topics as |t|}}
    {{latest-topic-list-item-cat topic=t}}
  {{/each}}
  
  */
  {
    "id": "KePqAmTh",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"topic\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `topics` property path was used in the `discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-contents-cat.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"t\"],false,[\"each\",\"-track-array\",\"latest-topic-list-item-cat\"]]",
    "moduleName": "discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-contents-cat.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});