import { template as template_440982da9d1e43f4a7961c1d706a2245 } from "@ember/template-compiler";
const FKText = template_440982da9d1e43f4a7961c1d706a2245(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
