import { template as template_ecd244adb5db4d82b285299d655fcb77 } from "@ember/template-compiler";
const WelcomeHeader = template_ecd244adb5db4d82b285299d655fcb77(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
