import { template as template_de92796691f14994ad39e113a7283968 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_de92796691f14994ad39e113a7283968(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
