import { template as template_8af0d24b5db0461bb91132603d3f6738 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8af0d24b5db0461bb91132603d3f6738(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
