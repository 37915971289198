import { template as template_fe31ead87c79401cb371be9e9435c2e1 } from "@ember/template-compiler";
const FKLabel = template_fe31ead87c79401cb371be9e9435c2e1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
