define("discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-cat", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if topics}}
    {{latest-topic-list-contents-cat topics=topics}}
    <div class="more-topics">
      <a href="/latest" class="btn pull-right">{{i18n "more"}}</a>
    </div>
  {{else}}
    <div class='no-topics'> 
      <h3>{{i18n "topics.none.latest"}}</h3> 
    </div>
  {{/if}}
  
  */
  {
    "id": "ITzlAxr8",
    "block": "[[[41,[30,0,[\"topics\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"topics\"],[[30,0,[\"topics\"]]]]]],[1,\"\\n  \"],[10,0],[14,0,\"more-topics\"],[12],[1,\"\\n    \"],[10,3],[14,6,\"/latest\"],[14,0,\"btn pull-right\"],[12],[1,[28,[35,4],[\"more\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"no-topics\"],[12],[1,\" \\n    \"],[10,\"h3\"],[12],[1,[28,[35,4],[\"topics.none.latest\"],null]],[13],[1,\" \\n  \"],[13],[1,\"\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `topics` property path was used in the `discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-cat.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `topics` property path was used in the `discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-cat.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"latest-topic-list-contents-cat\",\"div\",\"a\",\"i18n\",\"h3\"]]",
    "moduleName": "discourse/plugins/discourse-category-home/discourse/templates/components/latest-topic-list-cat.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});