import { template as template_948ac2e8068646a2a6b17812b43b5741 } from "@ember/template-compiler";
const EmptyState = template_948ac2e8068646a2a6b17812b43b5741(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
